<div class="dialog-container">
  <h1 mat-dialog-title>Component</h1>

  <mat-dialog-content>
    <form [formGroup]="componentForm" class="dialog-form" *ngIf="currentComponent$ | async; else loading">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <mat-form-field floatLabel="always">
            <mat-label>Name</mat-label>
            <input matInput formControlName="component" required />
          </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-12">
          <mat-form-field floatLabel="always">
            <mat-label>Code</mat-label>
            <input matInput formControlName="componentCd" required />
            <mat-error *ngIf="componentForm.get('componentCd').errors?.maxlength">
              Must be 3 characters or less
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-12 col-sm-12">
          <mat-form-field floatLabel="always">
            <mat-label>Context Url</mat-label>
            <input matInput formControlName="contextUrl" required />
          </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-12">
          <mat-form-field floatLabel="always">
            <mat-label>Contract Repo Name</mat-label>
            <input matInput formControlName="contractRepoName" required />
          </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-12">
          <mat-form-field floatLabel="always">
            <mat-label>Jira Component</mat-label>
            <input matInput formControlName="jiraComponent" required />
          </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-12">
          <mat-form-field floatLabel="always">
            <mat-label>Display IND</mat-label>
            <mat-select formControlName="displayInd" required>
              <mat-option *ngFor="let option of options" [value]="option.value">
                {{ option.display }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-12">
          <mat-form-field floatLabel="always">
            <mat-label>Generated Types IND</mat-label>
            <mat-select formControlName="generatedTypesInd" required>
              <mat-option *ngFor="let option of options" [value]="option.value">
                {{ option.display }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12 col-sm-12">
          <mat-form-field floatLabel="always">
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="componentDescription" required rows="4"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
    <ng-template #loading>
      <div class="component-dialog-loader">
        <div class="component-dialog-busyContainer">
          <xpo-busy-loader [slow]="true"></xpo-busy-loader>
        </div>
      </div>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button [disabled]="componentForm.invalid" (click)="save()">Save</button>
    <button mat-button color="primary" (click)="cancel()">Cancel</button>
  </mat-dialog-actions>
</div>
