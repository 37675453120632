import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { NoSpaceOnlyValidator } from '../functions/no-space-only.validator';

@Directive({
  selector: '[noSpaceOnly]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NoSpaceOnlyDirective, multi: true }],
})
export class NoSpaceOnlyDirective implements Validator {
  private noSpaceFn = NoSpaceOnlyValidator();
  validate(control: AbstractControl): { [key: string]: any } {
    return this.noSpaceFn(control);
  }
}
