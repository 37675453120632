import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiMetadataApiService, Component as ComponentSDK } from '@xpo-ltl/sdk-apimetadata';
import { AuditInfo, User } from '@xpo-ltl/sdk-common';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ArsenalComponentsService } from '../../arsenal-components/services/arsenal-components.service';

@Component({
  selector: 'app-component-detail-dialog',
  templateUrl: './component-detail-dialog.component.html',
  styleUrls: ['./component-detail-dialog.component.scss'],
})
export class ComponentDetailDialogComponent implements OnInit {
  componentForm: UntypedFormGroup;
  currentUser: User;
  currentComponentCd: string;
  currentComponent$: Observable<ComponentSDK>;
  currentComponent: ComponentSDK;
  editMode: boolean = false;
  options = [];

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ComponentDetailDialogComponent>,
    private arsenalComponentsService: ArsenalComponentsService,
    public apiMetadataApiService: ApiMetadataApiService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.options.push({ display: 'Y', value: true });
    this.options.push({ display: 'N', value: false });

    this.componentForm = this.fb.group({
      component: ['', Validators.required],
      componentDescription: ['', Validators.required],
      componentCd: ['', [Validators.required, Validators.maxLength(3)]],
      contextUrl: ['', [Validators.required]],
      contractRepoName: ['', [Validators.required]],
      jiraComponent: ['', [Validators.required]],
      displayInd: [false, Validators.required],
      generatedTypesInd: [false, Validators.required],
    });
    this.currentComponentCd = data ? data.componentCd : null;
  }

  ngOnInit() {
    this.apiMetadataApiService.loggedInUser().subscribe((user: User) => (this.currentUser = user));
    this.initializeForm();
  }

  initializeForm(): void {
    if (this.currentComponentCd) {
      this.editMode = true;
      this.currentComponent$ = this.arsenalComponentsService.getComponentDetails(this.currentComponentCd).pipe(
        tap((component) => {
          this.currentComponent = component;
          this.componentForm.patchValue(component);
        })
      );
    } else {
      this.currentComponent$ = of({} as ComponentSDK);
    }
  }

  save(): void {
    let component = new ComponentSDK();

    if (this.editMode) {
      component = this.currentComponent;
    }

    component.component = this.componentForm.get('component').value;
    component.componentDescription = this.componentForm.get('componentDescription').value;
    component.componentCd = this.componentForm.get('componentCd').value;
    component.contextUrl = this.componentForm.get('contextUrl').value;
    component.contractRepoName = this.componentForm.get('contractRepoName').value;
    component.jiraComponent = this.componentForm.get('jiraComponent').value;
    component.displayInd = this.componentForm.get('displayInd').value;
    component.generatedTypesInd = this.componentForm.get('generatedTypesInd').value;

    let auditInfo = new AuditInfo();

    if (this.editMode) {
      auditInfo = component.auditInfo;
      auditInfo.updateById = this.currentUser.userId;
      auditInfo.updatedTimestamp = new Date();
    } else {
      auditInfo.createdById = this.currentUser.userId;
      auditInfo.createdTimestamp = new Date();
    }

    component.auditInfo = auditInfo;

    if (this.editMode) {
      this.arsenalComponentsService
        .updateComponent(component)
        .subscribe((resp) => this.dialogRef.close({ action: 'UPDATE' }));
    } else {
      this.arsenalComponentsService
        .addComponent(component)
        .subscribe((resp) => this.dialogRef.close({ action: 'CREATE' }));
    }
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
