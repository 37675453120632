import { Injectable } from '@angular/core';
import { ApiMetadataApiService, Component } from '@xpo-ltl/sdk-apimetadata';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ArsenalComponentsService {
  constructor(private apiMetadataApiService: ApiMetadataApiService) {}

  getComponents(): Observable<Component[]> {
    return this.apiMetadataApiService.listComponents({ listInfo: null }).pipe(map((resp) => resp.components));
  }

  getComponentDetails(componentCd: string): Observable<Component> {
    return this.apiMetadataApiService.getComponent({ componentCd }).pipe(map((resp) => resp.component));
  }

  updateComponent(component: Component): Observable<Component> {
    return this.apiMetadataApiService.updateComponent({ component }).pipe(map((resp) => resp.component));
  }

  addComponent(component: Component): Observable<Component> {
    return this.apiMetadataApiService.createComponent({ component }).pipe(map((resp) => resp.component));
  }

  deleteComponent(componentCd: string): Observable<void> {
    return this.apiMetadataApiService.deleteComponent({ componentCd });
  }
}
