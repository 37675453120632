import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'message-tooltip',
  templateUrl: './message-tooltip.component.html',
  styleUrls: ['./message-tooltip.component.scss'],
})
export class MessageTooltipComponent implements ICellRendererAngularComp {
  private params: ICellRendererParams;
  msg: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.msg = params.data.errorMessage;
  }

  refresh(params: any): boolean {
    return false;
  }
}
