import { Component, Input, OnInit } from '@angular/core';
import { Contract } from '@xpo-ltl/sdk-apimetadata';

@Component({
  selector: 'contract-header',
  templateUrl: './contract-header.component.html',
  styleUrls: ['./contract-header.component.scss'],
})
export class ContractHeaderComponent {
  @Input() contract: Contract;
  @Input() noChangesState: boolean = false;
  constructor() {}
}
