export class CachedType {
  componentCd: string;
  typesList: any[];
  categoryCd: string;

  constructor(componentCd: string, typesList: any[], categoryCd: string) {
    this.componentCd = componentCd;
    this.typesList = typesList;
    this.categoryCd = categoryCd;
  }
}
