import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConstantsService {
  /** App constants go here */
  static tableRowHeight = 39;
  static confirmDialogWidth = '600px';
  static paginationSmall = 10;
  static paginationMedium = 15;
  static paginationLarge = 20;

  severityCodes = [
    { code: 'E', description: 'Error' },
    { code: 'I', description: 'Information' },
    { code: 'W', description: 'Warning' },
  ];

  errorReturnCodes = [
    { code: -10, description: 'Data not Found' },
    { code: -20, description: 'Mandatory Input Missing' },
    { code: -21, description: 'Input or Business Logic Validation Failed' },
    { code: -30, description: 'Optional Input Validation Failed' },
    { code: -40, description: 'Create Action Failed' },
    { code: -41, description: 'Update Action Failed' },
    { code: -42, description: 'Delete Action Failed' },
    { code: -60, description: 'Persistent Storage Failure' },
    { code: -999, description: 'Unexpected Exception' },
  ];

  infoReturnCode = [{ code: 1, description: 'Successful Completion' }];

  warningReturnCodes = [
    { code: 10, description: 'Data not Found' },
    { code: 20, description: 'Mandatory Input Missing' },
    { code: 21, description: 'Input or Business Logic Validation Failed' },
    { code: 30, description: 'Optional Input Validation Failed' },
    { code: 40, description: 'Create Action Failed' },
    { code: 41, description: 'Update Action Failed' },
    { code: 42, description: 'Delete Action Failed' },
    { code: 60, description: 'Persistent Storage Failure' },
    { code: 999, description: 'Unexpected Exception' },
  ];

  allReturnCodes = {
    I: this.infoReturnCode,
    E: this.errorReturnCodes,
    W: this.warningReturnCodes,
  };
}
