import { Component, Input, OnInit } from '@angular/core';
import { Contract, ContractType, ContractTypeAttribute, Feature, TypeAttribute } from '@xpo-ltl/sdk-apimetadata';
import { ApiContractTypeCategoryCd } from '@xpo-ltl/sdk-common';
import { ContractsService } from '../../../arsenal-components/services/contracts.service';
import { JsonViewModel } from './models/json-view.model';

@Component({
  selector: 'contract-item',
  templateUrl: './contract-item.component.html',
  styleUrls: ['./contract-item.component.scss'],
})
export class ContractItemComponent implements OnInit {
  @Input() feature: Feature;
  @Input() componentCd: string;
  @Input() serviceName: string;
  @Input() panelExpanded: boolean = false;

  contract: Contract;
  response: ContractType;
  request: ContractType;
  hasParameters: boolean;
  requestJson: JsonViewModel;
  responseJson: JsonViewModel;

  constructor(private contractService: ContractsService) {}

  ngOnInit(): void {
    this.contractService
      .getContractDetails(this.componentCd, this.feature?.featureId, this.serviceName)
      .subscribe((contract: Contract) => {
        this.contract = { ...contract };
        this.hasParameters = contract.contractType.some((c) => {
          return (
            c.contractCategoryCd === ApiContractTypeCategoryCd.PATH ||
            c.contractCategoryCd === ApiContractTypeCategoryCd.QUERY
          );
        });
        this.response = contract.contractType.find((c) => c.contractCategoryCd === ApiContractTypeCategoryCd.RESPONSE);
        this.request = contract.contractType.find((c) => c.contractCategoryCd === ApiContractTypeCategoryCd.REQUEST);

        if (this.request) {
          this.requestJson = new JsonViewModel(this.request);
        }

        if (this.response) {
          this.responseJson = new JsonViewModel(this.response);
        }
      });
  }

  convertNumber(n: string): number {
    return Number(n);
  }
}
