import { Component, ViewEncapsulation } from '@angular/core';
import { GridLink } from '../../models/grid-link';

@Component({
  selector: 'grid-links',
  templateUrl: './grid-links.component.html',
  styleUrls: ['./grid-links.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'lau-GridLinks lau-CellText-ellipsis',
  },
})
export class GridLinksComponent {
  params;

  agInit(params) {
    this.params = params;
  }

  callAction(action: Function): void {
    action(this.params.actionParams);
  }
}
