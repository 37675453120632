import { AfterViewInit, Component, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ValidationKeys } from '../../enums/lau-keys';

@Component({
  selector: 'app-grid-numeric-editor',
  templateUrl: './grid-numeric-editor.component.html',
  styleUrls: ['./grid-numeric-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'lau-NumericCellEditor',
  },
})
export class GridNumericEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  value: number;
  maxLength: number;
  @ViewChildren('input') input;

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
    this.maxLength = this.params.maxLength;
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.input.first.nativeElement.focus();
    });
  }

  getValue(): any {
    return this.value;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd(): boolean {
    return this.value.toString().length > this.maxLength;
  }

  onKeyDown(event): void {
    if (!this.isDeleting(event.key) && !this.isKeyPressedNumeric(event.key)) {
      if (event.preventDefault) {
        event.preventDefault();
      }
    }
  }

  onFocusOut(e) {
    if (e.sourceCapabilities) {
      this.params.api.stopEditing();
    }
  }

  private isKeyPressedNumeric(value): boolean {
    return !!/\d/.test(value);
  }

  private isDeleting(key): boolean {
    return key === ValidationKeys.BACKSPACE || key === ValidationKeys.DELETE;
  }
}
