import { Directive, ElementRef, HostListener, Input } from '@angular/core';
@Directive({
  selector: '[specialIsAlphaNumeric]',
})
export class SpecialCharacterDirective {
  @Input() regexStr: string;
  @Input() isAlphaNumeric: boolean;
  @Input() allow: boolean = true;
  @Input() validateCP: boolean = false;

  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return this.allow ? new RegExp(this.regexStr).test(event.key) : !new RegExp(this.regexStr).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    if (this.validateCP) {
      this.validateFields(event);
    }
  }

  validateFields(event) {
    const value = event.clipboardData.getData('text');
    const prevent = !new RegExp(this.regexStr).test(value);

    if (prevent) {
      event.preventDefault();
    }
  }
}
